import React from "react";
import { navBarItems } from "./NavigationBar";
import resources from "../resources"
const instagramBtn = resources.icons.socialMedia.instagramBtn
const thingiverseBtn = resources.icons.thingiverseBtn
const youtubeBtn = resources.icons.socialMedia.youtubeBtn

const webLinks = navBarItems;
const socialMediaLinks = [
  { label: "Thingiverse", lnk: "https://www.thingiverse.com/thebinaryartisan/designs", icon: thingiverseBtn },
  { label: "Youtube", lnk: "https://www.youtube.com/channel/UCCHVf7HeGUcX6d94YPUFhfg", icon: youtubeBtn },
  { label: "Instagram", lnk: "https://www.instagram.com/thebinaryartisan/?igshid=MzRlODBiNWFlZA%3D%3D", icon: instagramBtn },
];

const OurWorkLinks = [
  { label: "Count Your Cards", lnk: "https://countYourCards.com" },
  { label: "ImgTransformer.com", lnk: "https://ImgTransformer.com" },
];

const SocialMediaLinks = () => {
  return (
    <div className="w-full text-center">
      <div className="inline-block">
        {socialMediaLinks.map(({ label, lnk, icon }, i) => (
          <div
            key={i}
            className="inline-block rounded-full shadow-sm tablet:mx-5 laptop:mx-20"
          >
            <a
              href={lnk}
              target="_blank"
              rel="noopener noreferrer"
              className="justify-center text-center"
            >
              <img
                src={icon}
                alt={label}
                className="h-auto m-2 w-14 tablet:w-20 tablet:h-auto laptop:w-24 bg-background1"
              />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

const WebLinks = () => {
  return (
    <div className="block ">
      <p className="w-full underline text-md underline-offset-1 text-txtColor3 font-fira">Quick Links</p>
      {webLinks.map(({ label, lnk }, i) => {
        return (
          <div key={i} className="">
            <a href={lnk} className="w-full mx-5 text-sm tablet:text-md text-txtColor1 hover:bg-text-gray-800 font-fira">
              {label}
            </a>
          </div>
        );
      })}
    </div>
  );
};

const OurWork = () => {
  return (
    <div className="block">
      <p className="w-full underline text-md underline-offset-1 text-txtColor3 font-fira">Our Work</p>
      {OurWorkLinks.map(({ label, lnk }, i) => (
        <div key={i} className="">
          <a href={lnk} className="w-full mx-5 text-sm tablet:text-md text-txtColor1 hover:bg-text-gray-800 font-fira">
            {label}
          </a>
        </div>
      ))}
    </div>
  );
};

const Footer = () => {
  return (
    <div className="">
      <div className="z-50 justify-center p-3 my-2 shadow-lg shadow-accent2"></div>
      <div className="flex-col mb-6 bg-background1 mobile:block tablet:flex" >
        <div className="mb-6">{SocialMediaLinks()}</div>
        <div className="justify-center block text-center tablet:text-left tablet:flex ">
          <div className="mx-5">{OurWork()}</div>
          <div className="mx-5">{WebLinks()}</div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
